import * as React from 'react'
import { t, tCurrency } from 'translations/translationFunctions'
import './OptionsSummaryItem.css'
import { formatCurrency } from '@omnicar/sam-format'
import SamCheckbox from '../samCheckbox/SamCheckbox'
import { ISummaryOption } from './OptionsSummary'

export interface IOptionsSummaryItemProps {
  option: ISummaryOption
  handleChange?: (e: React.ChangeEvent<HTMLElement>) => void
  className?: string
}

const OptionsSummaryItem: React.SFC<IOptionsSummaryItemProps> = ({ option, handleChange }) => {
  return (
    <li className={`OptionsSummaryItem ${option.selected ? '' : 'd-print-none'}`}>
      <label className="row">
        <div className="col">
          <SamCheckbox
            label={option.option.description}
            checked={option.selected!}
            onChange={handleChange}
            id={option.option.id}
            readOnly={option.readOnly}
          />
          {option.option.termsOfService && option.option.termsOfService.ref && (
            <a href={option.option.termsOfService.ref} target="_blank" rel="noopener noreferrer">
              <strong className="text-bolder">{`${t('Terms')} (PDF)`}</strong>
            </a>
          )}
        </div>
        <div className="col-3 custom-control-description unselectable text-right">
          {formatCurrency(option.option.price.priceInclVat) +
            ' ' +
            tCurrency(`${option.option.price.currency} per month`)}
        </div>
      </label>
    </li>
  )
}

export default OptionsSummaryItem
