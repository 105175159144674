import * as React from 'react'
import SectionIndicator from './SectionIndicator'
import { t } from 'translations/translationFunctions'
import classnames from 'classnames'

export interface ISectionIndicatorHeaderProps {
  className?: string
  activeSection: number
}

const SectionIndicatorHeader: React.SFC<ISectionIndicatorHeaderProps> = ({ className, activeSection }) => {
  const classes: string = classnames('SectionIndicatorHeader', className)

  return (
    <section className={classes}>
      <div className="row no-gutters">
        <div className="col">
          <SectionIndicator title={t('Car')} active={activeSection === 0} iconClass="sam-icon-car" />
        </div>

        <div className="col ml-1">
          <SectionIndicator
            title={t('Subscription Contract')}
            active={activeSection === 1}
            iconClass="sam-icon-contract"
          />
        </div>

        <div className="col ml-1">
          <SectionIndicator
            title={t('Personal information')}
            active={activeSection === 2}
            iconClass="sam-icon-avatar"
          />
        </div>
      </div>
    </section>
  )
}

export default SectionIndicatorHeader as React.SFC<ISectionIndicatorHeaderProps>
