import keys from '../ActionTypeKeys'
import {
  Customer,
  IContractOfferRequest,
  IContractOptionResponse,
  IContractTemplateResponse,
  VehicleAlongItsContracts,
  VehicleContracts,
} from '@omnicar/sam-types'

export interface ISetVehicle {
  readonly type: keys.SET_VEHICLE
  readonly vehicle: VehicleAlongItsContracts
}

export interface ISetVehicleContracts {
  readonly type: keys.SET_VEHICLE_CONTRACTS
  readonly vehicleContracts: VehicleContracts
}

export interface ISetCustomerInformation {
  readonly type: keys.SET_CUSTOMER_INFORMATION
  readonly customerInformation: Customer
}

export interface IUpdateCustomerInformation {
  readonly type: keys.UPDATE_CUSTOMER_INFORMATION
  readonly customerInformation: Partial<Customer>
}

export interface IClearCustomerInformation {
  readonly type: keys.CLEAR_CUSTOMER_INFORMATION
}

export interface ISetStartMileage {
  readonly type: keys.SET_START_MILEAGE
  readonly startMileage: number
}

export interface ISetDurationMileage {
  readonly type: keys.SET_DURATION_MILEAGE
  readonly duration: number
  readonly mileage: number
}

export interface ISetSelectedTemplate {
  readonly type: keys.SET_SELECTED_TEMPLATE
  readonly template: IContractTemplateResponse
}

export interface ISetOptions {
  readonly type: keys.SET_OPTIONS
  readonly optionsSelected: IContractOptionResponse[]
}

export interface ISetOfferContract {
  readonly type: keys.SET_OFFER_CONTRACT
  readonly contractOffer: IContractOfferRequest
}

export function setVehicle(vehicle: VehicleAlongItsContracts): ISetVehicle {
  return {
    type: keys.SET_VEHICLE,
    vehicle,
  }
}

export function setVehicleContracts(vehicleContracts: VehicleContracts): ISetVehicleContracts {
  return {
    type: keys.SET_VEHICLE_CONTRACTS,
    vehicleContracts,
  }
}

export function setCustomerInformation(customerInformation: Customer): ISetCustomerInformation {
  return {
    type: keys.SET_CUSTOMER_INFORMATION,
    customerInformation,
  }
}

export function updateCustomerInformation(customerInformation: Partial<Customer>): IUpdateCustomerInformation {
  return {
    type: keys.UPDATE_CUSTOMER_INFORMATION,
    customerInformation,
  }
}

export function clearCustomerInformation(): IClearCustomerInformation {
  return {
    type: keys.CLEAR_CUSTOMER_INFORMATION,
  }
}

export function setStartMileage(startMileage: number): ISetStartMileage {
  return {
    type: keys.SET_START_MILEAGE,
    startMileage,
  }
}

export function setDurationMileage(duration: number, mileage: number): ISetDurationMileage {
  return {
    type: keys.SET_DURATION_MILEAGE,
    duration,
    mileage,
  }
}

export function setSelectedTemplate(template: IContractTemplateResponse): ISetSelectedTemplate {
  return {
    type: keys.SET_SELECTED_TEMPLATE,
    template,
  }
}

export function setOptions(optionsSelected: IContractOptionResponse[]): ISetOptions {
  return {
    type: keys.SET_OPTIONS,
    optionsSelected,
  }
}

export function setOfferContract(contractOffer: IContractOfferRequest): ISetOfferContract {
  return {
    type: keys.SET_OFFER_CONTRACT,
    contractOffer,
  }
}
