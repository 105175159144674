import { formatCurrency, formatDate } from '@omnicar/sam-format'
import { ISubscription, PriceSpecification } from '@omnicar/sam-types'
import React from 'react'
import { t, tCurrency } from 'translations/translationFunctions'
import './BillingSummary.css'

interface IProps {
  downpayment: PriceSpecification
  subscriptions: ISubscription[]
  minimumPaymentsCount: number | null
  totalAmount: PriceSpecification | null
  minimumTotalAmount: PriceSpecification | null
  hiddenVAT: boolean
}

export default class Billings extends React.Component<IProps> {
  public render() {
    const { downpayment, subscriptions, minimumTotalAmount, totalAmount, hiddenVAT } = this.props
    const hasSubscriptions = subscriptions.length > 0
    const subscription = subscriptions[0]
    const orgNumberOfPayments = hasSubscriptions ? subscription.numberOfPayments : 0

    // Due to an error in calculation module subscription.numberOfPayments sometimes is a non-integer number
    // To fix this temporarily:
    // If it differs < 0.5 from an integer then round the number
    // If it diffs more then hide number of payments and last payment date
    let numberOfPayments = Math.round(orgNumberOfPayments)
    let showNumberOfPayments = false
    let monthlyAmountLabel: string
    if (Math.abs(numberOfPayments - orgNumberOfPayments) <= 0.5) {
      monthlyAmountLabel = t('Subscription %numOfPayments payments each of', { numOfPayments: numberOfPayments })
      showNumberOfPayments = true
    } else {
      monthlyAmountLabel = t(hiddenVAT ? 'Price per month' : 'Price per month incl. VAT')
      showNumberOfPayments = false
    }

    // Make sure the total price shows the monthly price x nPayments, even if due to rounding errors it would look otherwise
    if (subscription) {
      const pricePPaymentInclVAT = Math.round(subscription.amountPrPayment.priceInclVat * 100) / 100
      subscription.amountPrPayment.priceInclVat = pricePPaymentInclVAT
      if (totalAmount) totalAmount.priceInclVat = downpayment.priceInclVat + numberOfPayments * pricePPaymentInclVAT
      this.calcMiminimumTotalAmountRounded(minimumTotalAmount, pricePPaymentInclVAT)
    }

    return (
      <div className="col-12 billing-wrapper">
        <div className="row__billing">
          <h3 className="billing__label">{t('To pay now')}:</h3>
          <h3 className="billing__value">{this.renderPaymentValue(downpayment)}</h3>
        </div>
        {hasSubscriptions && (
          <div className="row__billing">
            <span className="billing__label">{monthlyAmountLabel}:</span>
            <span className="billing__value">{this.renderPaymentValue(subscription.amountPrPayment)}</span>
          </div>
        )}
        {hasSubscriptions && (
          <div className="row__billing">
            <span className="billing__label">{t('First monthly payment')}:</span>
            <span className="billing__value">{formatDate(subscription.firstPaymentDate)}</span>
          </div>
        )}
        {hasSubscriptions && showNumberOfPayments && (
          <div className="row__billing">
            <span className="billing__label">{t('Last monthly payment')}:</span>
            <span className="billing__value">{formatDate(subscription.lastPaymentDate)}</span>
          </div>
        )}
        {hasSubscriptions && minimumTotalAmount && (
          <div className="row__billing">
            <span className="billing__label">{t('The minimum total amount will be')}:</span>
            <span className="billing__value">{this.renderPaymentValue(minimumTotalAmount)}</span>
          </div>
        )}
        {hasSubscriptions && totalAmount && (
          <div className="row__billing">
            <span className="billing__label">{t('The total amount will be')}:</span>
            <span className="billing__value">{this.renderPaymentValue(totalAmount)}</span>
          </div>
        )}
      </div>
    )
  }

  private calcMiminimumTotalAmountRounded(minimumTotalAmount: PriceSpecification | null, monthlyPayment: number) {
    if (!minimumTotalAmount) return
    const nMonths = Math.round(minimumTotalAmount.priceInclVat / monthlyPayment)
    minimumTotalAmount.priceInclVat = nMonths * monthlyPayment
  }

  private renderPaymentValue = (p: PriceSpecification) =>
    `${formatCurrency(p.priceInclVat)} ${tCurrency(p.currency).toUpperCase()}`
}
