import classnames from 'classnames'
import * as React from 'react'
import './SectionIndicator.css'

interface ISectionIndicatorProps {
  className?: string
  title: string
  active: boolean
  iconClass: string
}
const SectionIndicator: React.SFC<ISectionIndicatorProps> = ({ className, title, active, iconClass }) => {
  const classes: string = classnames('SectionIndicator', className, { active })
  const icon: string = classnames('icon', iconClass)

  return (
    <section className={classes} data-e2e={'indicator' + title}>
      <div className="">
        <div className="icon-title">
          <i className={icon} />
          <h1 className="title d-none d-sm-block ml-2 ml-md-3">{title}</h1>
        </div>
        <div className="bottom-line mt-auto">
          <div className="arrow-down" />
        </div>
      </div>
    </section>
  )
}

export default SectionIndicator
