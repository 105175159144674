import * as jwt from 'jsonwebtoken'
import { getGenericToken as getGenericTokenFromStorage, saveGenericToken } from 'store/localstorage'

const HOUR_TOKEN_DURATION = 60 * 60 // 1 hour
// algorithm should be syncronized with API
const algorithm = 'HS384'
// should be syncronized with API types
interface ISAMToken {
  version?: string
  type: 'WebcalcToken' // in API types has more options
  iat: number
  exp: number
}

const signJwt = (payload: object): string =>
  jwt.sign(payload, process.env.REACT_APP_TOKEN_SECRET || '', {
    algorithm,
  })

const verifyToken = (token: string): ISAMToken =>
  jwt.verify(token, process.env.REACT_APP_TOKEN_SECRET || '', {
    algorithms: [algorithm],
    ignoreExpiration: false,
  }) as ISAMToken

const formatToken = (
  type: ISAMToken['type'] = 'WebcalcToken',
  tokenDuration: number = HOUR_TOKEN_DURATION,
): ISAMToken => {
  const now = Math.floor(Date.now() / 1000)

  return {
    type,
    iat: now,
    exp: now + tokenDuration,
  }
}

const processNewGenericToken = (): string => {
  const newToken = signJwt(formatToken())
  saveGenericToken(newToken)
  return newToken
}

export const getGenericToken = (): string => {
  const token = getGenericTokenFromStorage()

  if (!token) {
    return processNewGenericToken()
  }

  try {
    verifyToken(token)
  } catch (error) {
    return processNewGenericToken()
  }

  return token
}
