import { combineReducers } from 'redux'
import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'
import IStoreState from '../store/IStoreState'
import contractDataReducer from './contractDataReducer'
import contractReducer from './contractReducer'
import initialState from './initialState'
import { outageReducer } from './outageReducer'
import paymentInfoReducer from './paymentInfoReducer'
import priceCalculationReducer from './priceCalculationReducer'
import providerBrandingReducer from './providerBrandingReducer'

const appReducer = combineReducers<IStoreState>({
  contractOffer: contractReducer,
  contractData: contractDataReducer,
  paymentInfo: paymentInfoReducer,
  priceCalculation: priceCalculationReducer,
  providerData: providerBrandingReducer,
  outage: outageReducer,
})

const rootReducer = (state: IStoreState | undefined = initialState, action: ActionTypes): IStoreState => {
  if (action.type === ActionTypeKeys.RESET_STORE) {
    state = {
      ...initialState,
      providerData: state.providerData ? { ...state.providerData } : initialState.providerData,
    }
  }

  return appReducer(state, action)
}

export default rootReducer
