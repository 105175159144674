import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CustomerInformationPage from '../../pages/customerInformation/CustomerInformationPage'
// import ModelSelectionPage from '../../pages/modelSelection/ModelSelectionPage'
import DurationMileagePage from '../../pages/durationMileage/DurationMileagePage'
import Error404Page from '../../pages/error404/Error404Page'
import OptionsPage from '../../pages/options/OptionsPage'
import OverviewPage from '../../pages/overview/OverviewPage'
import ReceiptPage from '../../pages/receipt/ReceiptPage'
import SummaryPage from '../../pages/summary/SummaryPage'
import TemplateSelectionPage from '../../pages/templateSelection/TemplateSelectionPage'
// import RegistrationSelectionPage from '../../pages/registrationSelection/RegistrationSelectionPage'
import VehicleIdentificationPage from '../../pages/vehicleIdentification/VehicleIdentificationPage'
import { withTracker } from '../../pages/WithTracker'
import {
  customerInformationPath,
  // modelSelectionPath,
  durationMileagePath,
  errorPath,
  optionsPath,
  overviewPath,
  receiptPath,
  summaryPath,
  templateSelectionPath,
  // registrationSelectionPath,
  vehicleIdentificationPath,
} from './paths'

export default function Routes() {
  return (
    <Switch>
      <Route exact={true} path={vehicleIdentificationPath} render={withTracker(VehicleIdentificationPage)} />
      <Route path={durationMileagePath} render={withTracker(DurationMileagePage)} />
      <Route path={templateSelectionPath} render={withTracker(TemplateSelectionPage)} />
      <Route path={optionsPath} render={withTracker(OptionsPage)} />
      <Route path={customerInformationPath} render={withTracker(CustomerInformationPage)} />
      <Route path={summaryPath} render={withTracker(SummaryPage)} />
      <Route path={overviewPath} render={withTracker(OverviewPage)} />
      <Route path={receiptPath} render={withTracker(ReceiptPage)} />
      <Route path={errorPath} render={withTracker(Error404Page)} />
    </Switch>
  )
}
