import React, { Component, ReactElement } from 'react'
import { RouteComponentProps } from 'react-router'
import { trackPageView } from '../utils/analytics'

class Tracker extends Component<RouteComponentProps<any>, {}> {
  public componentDidMount() {
    const page = this.props.location.pathname
    trackPageView(page)
  }

  public componentWillReceiveProps(nextProps: RouteComponentProps<any>) {
    const currentPage = this.props.location.pathname
    const nextPage = nextProps.location.pathname

    if (currentPage !== nextPage) {
      trackPageView(nextPage)
    }
  }

  public render() {
    const { children, ...rest } = this.props
    if (Array.isArray(children)) {
      return React.Children.map(children, child => React.cloneElement(child as ReactElement<any>, rest))
    } else {
      return React.cloneElement(this.props.children as ReactElement<any>, rest)
    }
  }
}

export const withTracker = (Component: React.ComponentClass<any>) => {
  return (props: RouteComponentProps<any>): React.ReactNode => {
    return (
      <Tracker {...props}>
        <Component />
      </Tracker>
    )
  }
}
