enum ActionTypeKeys {
  SET_VEHICLE = 'SET_VEHICLE',
  SET_VEHICLE_CONTRACTS = 'SET_VEHICLE_CONTRACTS',
  SET_CUSTOMER_INFORMATION = 'SET_CUSTOMER_INFORMATION',
  UPDATE_CUSTOMER_INFORMATION ='UPDATE_CUSTOMER_INFORMATION',
  CLEAR_CUSTOMER_INFORMATION = 'CLEAR_CUSTOMER_INFORMATION',
  SET_START_MILEAGE = 'SET_START_MILEAGE',
  SET_DURATION_MILEAGE = 'SET_DURATION_MILEAGE',
  SET_OPTIONS = 'SET_OPTIONS',
  SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE',
  SET_OFFER_CONTRACT = 'SET_OFFER_CONTRACT',
  // contractDataActions
  SET_BRANDS = 'SET_BRANDS',
  SET_MODELS = 'SET_MODELS',
  SET_FUEL_TYPES = 'SET_FUEL_TYPES',
  SET_DURATIONS = 'SET_DURATIONS',
  SET_TEMPLATES = 'SET_TEMPLATES',
  SET_AVAILABLE_OPTIONS = 'SET_AVAILABLE_OPTIONS',
  // payment info
  SET_PAYMENT_INFO = 'SET_PAYMENT_INFO',
  // price calculation
  SET_PRICE_CALCULATION = 'SET_PRICE_CALCULATION',
  // provider branding
  SET_PROVIDER_DATA = 'SET_PROVIDER_DATA',
  // OFFER REQUEST
  OFFER_REQUEST_UPDATE = 'OFFER_REQUEST_UPDATE',
  OFFER_REQUEST_UPLOAD_FILE = 'OFFER_REQUEST_UPLOAD_FILE',

  // Outage handling
  SET_OUTAGE = 'SET_OUTAGE',

  RESET_STORE = 'RESET_STORE',
  CLEAR_STORE = 'CLEAR_STORE',  
}

export default ActionTypeKeys
