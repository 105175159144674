/**
 * Component to render Provider Stylesheets from public/assets/css folder
 *
 * To develop provider styling:
 * 1. Make a new folder under 'src/styling/provider/[provider id]'
 * 2. Create an index.scss in the folder for the styling using SCSS
 * 3. Run 'npm run build:providerstyling' to build styling
 * 4. Include builded css file ('public' folder) when committing changes
 *
 */

import { IBrandingPayloadResponse } from '@omnicar/sam-types'
import * as React from 'react'
import { connect } from 'react-redux'
import IStoreState from '../../store/IStoreState'
import { providerId } from '../routes/paths'

const stylePath: string = process.env.PUBLIC_URL + '/assets/css'

interface IProps {
  providerData: IBrandingPayloadResponse | null
}

const SFCProviderStyling: React.SFC<IProps> = ({ providerData }) => {
  if (providerData && providerData.providerStyling && providerData.providerStyling.hasProviderStyling) {
    const url: string = `${stylePath}/${providerId}.min.css`
    return <link rel="stylesheet" type="text/css" href={url} />
  }
  return null
}

const mapStateToProps = (state: IStoreState) => {
  return {
    providerData: state.providerData,
  }
}

const ProviderStyling = connect(mapStateToProps)(SFCProviderStyling)
export default ProviderStyling
