import * as Sentry from '@sentry/browser'

export const reportErrors = process.env.REACT_APP_REPORT_ERRORS === '1'

export const initSentry = () => {
  if (reportErrors) {
    Sentry.init({
      dsn: 'https://435b69166a034d7c997e5c53335cfb50@sentry.io/1332517',
      environment: process.env.REACT_APP_BRANCH || process.env.REACT_APP_ENVIRONMENT,
    })
  }
}

export const captureEvent = (event: Sentry.SentryEvent) => {
  if (reportErrors) {
    Sentry.captureEvent(event)
  }
}

export const captureException = (e: any) => {
  if (reportErrors) {
    Sentry.captureException(e)
  }
}

export const captureMessage = (message: string) => {
  if (reportErrors) {
    Sentry.captureMessage(message)
  }
}

export const reportMissingTranslation = (level: Sentry.Severity) => (message: string) =>
  captureEvent({
    message,
    level,
  })
