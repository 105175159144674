import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'
import initialState from './initialState'
import { IContractOfferResponse } from '@omnicar/sam-types'

export default function paymentInfoReducer(
  state = initialState.paymentInfo,
  action: ActionTypes,
): IContractOfferResponse | null {
  switch (action.type) {
    case ActionTypeKeys.SET_PAYMENT_INFO:
      return action.paymentInfo
    default:
      return state
  }
}
