import keys from '../ActionTypeKeys'

export interface IResetReduxStore {
  readonly type: keys.RESET_STORE
}

export function resetReduxStore(): IResetReduxStore {
  return {
    type: keys.RESET_STORE,
  }
}

export interface IResetReduxStore {
  readonly type: keys.RESET_STORE
}
