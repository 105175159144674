import React from 'react'
import classnames from 'classnames'
import SamCheckbox from '../../samCheckbox/SamCheckbox'

interface IProps {
  className?: string
  checked: boolean
  onChange: (id: number, value: boolean) => void
  error?: boolean
  href?: string
  linkText?: string
  text: string
  id: number | string
}

export default class TermsEntry extends React.PureComponent<IProps> {
  public render() {
    const { checked, error, text, href, linkText, id } = this.props

    const labelClass = classnames('ml-2', {
      'is-invalid': error,
      'is-valid': !error,
    })
    const borderClass = classnames('terms-conditions-accept', { 'error-border': error })

    return (
      <section>
        <div className="row">
          <div className="col-sm-8 offset-sm-2 text-center" />
          <div className="col-12">
            <div className={borderClass}>
              <label
                className={'row custom-control-label' + labelClass}
                htmlFor={'sam-check-' + id}
                data-e2e="terms-span">
                <span className="col-2 col-md-1">
                  <SamCheckbox checked={checked} onChange={this.handleChange} id={id} readOnly={false} />
                </span>
                <span className="col-10 col-md-11 option-description-wrapper">
                  <span className="option-description">
                    {text}
                    {linkText && (
                      <React.Fragment>
                        {' '}
                        {href ? (
                          <a href={href} title={linkText} target="_blank" rel="noopener noreferrer">
                            {linkText}
                          </a>
                        ) : (
                          <span title={linkText}>{linkText}</span>
                        )}
                      </React.Fragment>
                    )}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </section>
    )
  }
  private handleChange = () => {
    const { id, checked, onChange } = this.props
    onChange(+id, !checked)
  }
}
