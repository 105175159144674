import { IReplacement, TranslationKey } from './translationTypes'
import { tUntyped } from '@omnicar/sam-translate'
import { IsoLocale } from '@omnicar/sam-types'

/**
 * Translates a given phrase using replacements and a locale
 * @param key phrase to translate
 * @param replacements optional replacements as key/value object
 * @param locale optional locale to use for translations
 */
export function t(key: TranslationKey, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  return tUntyped(key, replacements, locale)
}

export function tCurrency(key: string, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  return tUntyped(key, replacements, locale)
}

export function tFuelType(key: string, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  return tUntyped(key, replacements, locale)
}
