import { IsoLocale } from '@omnicar/sam-types'
export type LocaleType = 'en' | 'sv' | 'da' | 'fi' | 'no'

/**
 * Note:
 * Locale is a string in the form 'xx-yy', where:
 *   xx = two letter language code
 *   yy = two letter country/region code
 * Language is only the two letter language code.
 *
 * @param locale A locale code in the form 'xx-yy'.
 * @returns A two letter language code (short locale) from the provided locale.
 */
export const localeToLanguage = (locale: string): string => (locale ? locale.substr(0, 2) : '')

export const resolveProviderLocale = (locale?: null | IsoLocale) => localeToLanguage(locale || 'da-DK') as LocaleType
