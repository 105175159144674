import React from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import { connect } from 'react-redux'
import { t } from 'translations/translationFunctions'

class Error404Page extends React.Component<{}, {}> {
  public render() {
    return (
      <section className="Error404Page">
        <PageHeader pageTitle="fourOhFour" />
        <div className="page-content">
          <p className="component-margin-top-small text-center">
            {`${t(
              'Sorry, but you do not have access to this page. Please contact customer support if you think this is a mistake',
            )}.`}
          </p>
        </div>
      </section>
    )
  }
}

export default connect(
  null,
  null,
)(Error404Page as any)
