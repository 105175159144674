import IStoreState from '../../store/IStoreState'

export default {
  contractOffer: {
    contractProviderId: 0,
    duration: 0,
    mileage: 0,
    startMileage: 0,
    options: [],
    paymentGateway: 'Stripe',
    startDateISOString: '',
  },
  contractData: {
    brands: [],
    models: [],
    fuelTypes: [],
    options: [],
    durationOptions: [],
  },
  paymentInfo: null,
  priceCalculation: null,
  providerData: null,
  outage: undefined,
} as IStoreState
