import React from 'react'
import { t } from 'translations/translationFunctions'
import './Login.css'
import { TranslationKey } from 'translations/translationTypes'

const LoginForm: React.SFC<{
  onSubmit: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCancel: () => void
  value: string
  error?: TranslationKey
}> = ({ onSubmit, onChange, onCancel, value, error }) => (
  <div className="LoginForm">
    <input
      onChange={onChange}
      className="form-control "
      placeholder={t('Your password')}
      name="password"
      type="password"
      required
      value={value}
      onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
    />
    {error && <span className="LoginForm__error-message">{t(error)}</span>}
    <div className="LoginForm__buttons-wrap">
      <button onClick={onSubmit} type="submit" className="btn d-inline-flex align-items-center sam-btn-primary">
        {t('Login')}
      </button>
      <button onClick={onCancel} type="cancel" className="btn d-inline-flex align-items-center sam-btn-secondary">
        {t('Cancel')}
      </button>
    </div>
  </div>
)

export default LoginForm
