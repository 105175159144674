import ActionTypeKeys from '../actions/ActionTypeKeys'
import ActionTypes from '../actions/ActionTypes'
import initialState from './initialState'
import { ContractData } from '../store/contractData'

export default function contractDataReducer(state = initialState.contractData, action: ActionTypes): ContractData {
  switch (action.type) {
    case ActionTypeKeys.SET_BRANDS:
      return {
        ...state,
        brands: action.brands,
        models: [],
        fuelTypes: [],
        durationOptions: [],
      }
    case ActionTypeKeys.SET_MODELS:
      return {
        ...state,
        models: action.models,
        fuelTypes: [],
        durationOptions: [],
      }
    case ActionTypeKeys.SET_FUEL_TYPES:
      return {
        ...state,
        fuelTypes: action.fuelTypes,
        durationOptions: [],
      }
    case ActionTypeKeys.SET_DURATIONS:
      return {
        ...state,
        durationOptions: action.durationOptions,
      }
    case ActionTypeKeys.SET_AVAILABLE_OPTIONS:
      return {
        ...state,
        options: action.options,
      }
    default:
      return state
  }
}
