import {
  Customer,
  IContractOptionResponse,
  IContractTemplateResponse,
  IsoLocale,
  TIsoCountry,
  VehicleAlongItsContracts,
} from '@omnicar/sam-types'
import { initZipCityCountry } from '@omnicar/sam-zip-city'
import * as React from 'react'
import { connect, Dispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import ActionTypes from '../../actions/ActionTypes'
import * as actions from '../../actions/contractActions/contractActions'
import { IClearCustomerInformation, ISetCustomerInformation } from '../../actions/contractActions/contractActions'
import CustomerInformationEntry, {
  ICustomerInformationEntryProps,
} from '../../components/customerInformationEntry/CustomerInformationEntry'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import PageHeader from '../../components/pageHeader/PageHeader'
import { providerPath, summaryPath } from '../../components/routes/paths'
import SectionIndicatorHeader from '../../components/sectionIndicatorHeader/SectionIndicatorHeader'
import SectionSummary from '../../components/sectionSummary/SectionSummary'
import IStoreState from '../../store/IStoreState'
import { defaultBack } from '../../utils/history'
import { withRequiredProps } from '../RequiredPropsWrapper'

interface ICustomerInformationPageProps extends RouteComponentProps<any> {
  setCustomerInformation: (customer: Customer) => ISetCustomerInformation
  clearCustomerInformation: () => IClearCustomerInformation
  customer: Customer
  selectedTemplate: IContractTemplateResponse
  vehicle: VehicleAlongItsContracts
  options: IContractOptionResponse[]
  mileage: number
  duration: number
  countryCode: TIsoCountry
  providerLocale: IsoLocale
}

interface ICustomerInformationPageState {
  showCustomerInformation: boolean
  showSpinner: boolean
  countryCode: TIsoCountry
  isEmailValid: boolean
}

class CustomerInformationPage extends React.Component<ICustomerInformationPageProps, ICustomerInformationPageState> {
  public state: ICustomerInformationPageState = {
    showCustomerInformation: true,
    showSpinner: false,
    countryCode: 'SE' as TIsoCountry,
    isEmailValid: true,
  }

  submitForm: () => void = () => {}

  public componentDidMount() {
    initZipCityCountry({ isoCountryCode: this.state.countryCode })
  }

  public render() {
    const {
      vehicle,
      mileage,
      duration,
      selectedTemplate,
      options,
      customer,
      countryCode,
      setCustomerInformation,
    } = this.props
    const { showCustomerInformation, isEmailValid } = this.state
    const entryProps: ICustomerInformationEntryProps = {
      customerSubmit: this.handleSubmit,
      customer,
      showCustomerInformation,
      // need shouldValidateCustomer for better security
      // shouldValidateCustomer: !isLoggedIn,
      provId: selectedTemplate.providerId,
      bindSubmitForm: this.bindSubmitForm,
      countryCode: countryCode,
      setCustomerInformation,
      isEmailValid,
      handleEmailValidation: this.handleEmailValidation,
    }

    return (
      <section className="CustomerInformationPage">
        <PageHeader />
        <SectionIndicatorHeader activeSection={2} />
        <div className="page-content">
          <SectionSummary
            vehicle={vehicle}
            mileage={mileage}
            duration={duration}
            template={selectedTemplate}
            options={options}
          />

          <div className="row component-margin-top-small">
            <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
              <CustomerInformationEntry {...entryProps} />
            </div>
          </div>
        </div>

        {
          <NavigationFooter
            next={providerPath(summaryPath)}
            prevCallback={defaultBack(this.props.history)}
            nextCallback={this.nextClicked}
          />
        }
      </section>
    )
  }

  private handleEmailValidation = (isEmailValid: boolean) => this.setState({ isEmailValid })

  private bindSubmitForm = (submitForm: () => void) => {
    this.submitForm = submitForm
  }

  private handleSubmit = (customer: Customer) => {
    if (!this.state.isEmailValid) {
      return
    }
    // We only override customer if we don't have a customer
    if (!this.props.customer) {
      this.props.setCustomerInformation(customer)
    }
    // continue 'shopping' flow
    this.props.history.push(providerPath(summaryPath))
  }

  private nextClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    this.submitForm()
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    vehicle: state.contractOffer.product,
    mileage: state.contractOffer.mileage,
    duration: state.contractOffer.duration,
    options: state.contractOffer.options,
    customer: state.contractOffer.customer,
    selectedTemplate: state.contractOffer.template,
    countryCode: state.providerData ? state.providerData.countryCode : 'SE',
    providerLocale: state.providerData ? state.providerData.locale : 'sv-SE',
    customization: state.providerData && state.providerData.providerCustomization,
  }
}

function mapDispatchToProps(dispatch: Dispatch<ActionTypes>) {
  return {
    setCustomerInformation: (customer: Customer) => dispatch(actions.setCustomerInformation(customer)),
    clearCustomerInformation: () => dispatch(actions.clearCustomerInformation()),
  }
}

const isStateComplete = (state: any): boolean => {
  return Boolean(state.selectedTemplate)
}

const wrappedComponent = withRequiredProps(isStateComplete)(CustomerInformationPage)
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent as any)
