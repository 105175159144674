import * as React from 'react'

interface TProps {
  pixelId?: string
}

interface TState {
  imgSrc: string
  jsCode: string
}

/**
 * Note, that this method doesn't remove ? & = / : character, since removing
 * any of these may render a possible URL link useless.
 */
const removeTagEntities = (text: string) => {
  return text.replace(/<|>|"|'|`/g, ' * ')
}

/**
 * TODO: marko, if this works, make the code cleaner, otherwise remove it altogether.
 * 
   In production this component will add the following script and HTML code:

    <div id="fbpixelcode">
      <!-- Facebook Pixel Code -->
      <script type="text/javascript">
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '453453534534534543');
        fbq('track', 'PageView');
      </script>
      <noscript>
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=453453534534534543&amp;ev=PageView&amp;noscript=1">
      </noscript>
      <!-- End Facebook Pixel Code -->
    </div>
 */
class FacebookPixelCode extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    const pixelId = props.pixelId || '0'
    const imageUrl = removeTagEntities(`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`)

    let imgSrc: string = ''
    let jsCode: string = ''

    if (process.env.NODE_ENV === 'production') {
      imgSrc = imageUrl
      jsCode =
        "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '" +
        pixelId +
        "');fbq('track', 'PageView');"
    } else {
      imgSrc = 'dummyImage'
      jsCode = '/* In production this will point to "' + imageUrl + '" */'
    }

    this.state = { imgSrc, jsCode }
  }

  createComment = (comment: string): any => {
    const elem = document.createComment(comment)

    return elem
  }

  createJsScript = (jsCode: string): HTMLScriptElement => {
    const scriptElem = document.createElement('script')

    scriptElem.type = 'text/javascript'
    // scriptElem.async = true
    scriptElem.innerHTML = jsCode

    return scriptElem
  }

  componentDidMount() {
    const { imgSrc, jsCode } = this.state
    const elemPixel = document.getElementById('fbpixelcode')

    const elemImg = document.createElement('img')
    elemImg.height = 1
    elemImg.width = 1
    elemImg.setAttribute('style', 'display:none')
    elemImg.src = imgSrc

    const elemNoscript = document.createElement('noscript')
    elemNoscript.appendChild(elemImg)

    if (elemPixel) {
      elemPixel.appendChild(this.createComment(' Facebook Pixel Code '))
      elemPixel.appendChild(this.createJsScript(jsCode))
      elemPixel.appendChild(elemNoscript)
      elemPixel.appendChild(this.createComment(' End Facebook Pixel Code '))
    }
  }

  render() {
    return (
      <>
        <div id="fbpixelcode" />
      </>
    )
  }
}

export default FacebookPixelCode
