import * as React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { tCurrency } from 'translations/translationFunctions'
import { IOptionEntry } from '../../pages/options/OptionsPage'
import './OptionEntry.css'
import { formatCurrency } from '@omnicar/sam-format'
import classnames from 'classnames'
import SamCheckbox from '../samCheckbox/SamCheckbox'

export interface IOptionsEntry {
  id: number
  option: IOptionEntry
  handleSelected?: (event: React.ChangeEvent<HTMLElement>) => void
}

const desktopTooltipDelay = { show: 50, hide: 300 }
const OptionEntry = ({ id, option, handleSelected }: IOptionsEntry) => {
  const classes: string = classnames('OptionEntryItem', 'd-flex')
  const entryId: string = 'entry-' + id

  return (
    <li className="OptionEntry">
      <div className={classes} data-e2e={option.option.id}>
        <SamCheckbox
          checked={option.selected}
          onChange={handleSelected}
          id={option.option.id}
          readOnly={option.readOnly}
        />

        <div className="custom-control-description unselectable">
          <span className="option-entry__description" id={entryId}>
            <b>{option.option.description}</b>
            {option.option.conditionText && <i className="fa fa-info option-entry__info" />}
          </span>
          {option.option.conditionText && (
            <span>
              {/* desktop */}
              <UncontrolledTooltip
                className="d-none d-sm-block sam-tooltip right"
                placement="right"
                target={entryId}
                autohide={false}
                delay={desktopTooltipDelay}>
                {option.option.conditionText}
              </UncontrolledTooltip>
              {/* mobile */}
              <UncontrolledTooltip
                className="d-block d-sm-none sam-tooltip top"
                placement="top"
                target={entryId}
                delay={50}>
                {option.option.conditionText}
              </UncontrolledTooltip>
            </span>
          )}

          <br />
          <span className="option-entry__price">
            {option.option.price && formatCurrency(option.option.price.priceInclVat)}
            {tCurrency(` ${option.option.price.currency.toUpperCase()} per month`)}
          </span>
        </div>
      </div>
    </li>
  )
}

export default OptionEntry
