import { DurationOptions } from '@omnicar/sam-types'
import { PriceSpecification } from '@omnicar/sam-types'

export const getDurationMileageTemplatePrice = (
  durationMileages: DurationOptions[],
  duration: number,
  mileage: number,
  templateId: number,
): PriceSpecification | null => {
  const durationMileagePrice = getDurationMileagePrices(durationMileages, duration, mileage)
  const selectedPrice = durationMileagePrice.find(p => p.contractId === templateId)
  return selectedPrice ? selectedPrice.price : null
}

export const getDurationMileagePrices = (
  durationMileages: DurationOptions[],
  duration: number,
  mileage: number,
): Array<{ contractId: number; price: PriceSpecification }> => {
  const selectedDuration = durationMileages.find(d => d.duration === duration)
  if (!selectedDuration) {
    return []
  }
  const selectedMileage = selectedDuration.options.find(m => m.mileage === mileage)
  return (selectedMileage && selectedMileage.prices) || []
}

export const getDurationOptions = (
  duration: number,
  options: DurationOptions[],
): { durationOptions: DurationOptions; index: number } => {
  let index = -1
  const foundOptions = options.find((durationOptions: DurationOptions, i: number) => {
    if (duration === durationOptions.duration) {
      index = i
      return true
    }
    return false
  })
  return {
    durationOptions: foundOptions!,
    index,
  }
}
