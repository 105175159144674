import React from 'react'
import classnames from 'classnames'
import { Vehicle, TIsoCountry } from '@omnicar/sam-types'
import { t } from 'translations/translationFunctions'
import Spinner from 'react-spinkit'

import './RegistrationNumberEntry.css'

export interface IRegistrationNumberEntryProps {
  className?: string
  inputChanged: (e: React.FormEvent<HTMLInputElement>) => void
  vehicle?: Vehicle | undefined
  nextStep: (e?: React.MouseEvent<HTMLElement>) => void
  inputValue: string
  hasInputError: boolean
  inputError: string
  isLoading: boolean
  setRef: (node: HTMLInputElement) => void
  mailTo?: string
  countryCode: TIsoCountry
  providerName?: string
}

const RegistrationNumberEntry = ({
  className,
  inputChanged,
  vehicle,
  nextStep,
  inputValue,
  hasInputError,
  inputError,
  isLoading,
  setRef,
  mailTo,
  countryCode,
  providerName,
}: IRegistrationNumberEntryProps) => {
  const classes: string = classnames('RegistrationNumberEntry', className)
  const error = hasInputError
  const inputClass = classnames('regnumber-input', 'form-control', {
    'is-invalid': error,
    'is-valid': vehicle !== undefined,
  })
  const errorStyle = { display: error ? 'block' : 'none' }

  const submit = (e: any) => {
    e.preventDefault()
    nextStep()
  }

  let placeholderForRegPlate: string = t('DD12314')
  switch (countryCode) {
    case 'DK':
      placeholderForRegPlate = 'AB12345'
      break
    case 'SE':
      placeholderForRegPlate = 'ABC123'
      break
    case 'FI':
      placeholderForRegPlate = 'ABC-123'
      break
    case 'NO':
      placeholderForRegPlate = 'AB12345'
      break
    default:
      placeholderForRegPlate = t('DD12314')
  }

  return (
    <section className={classes}>
      <div className="row">
        <div className="col">
          <h2 className="mb-4 text-center regnumber-input-title">{t('Enter the licenseplate of the vehicle')}</h2>
          <form onSubmit={submit} className="mb-2">
            <div
              className={`regnumber-input-container d-flex justify-content-center align-items-center ${countryCode}`}>
              <input
                className={inputClass}
                name="registration-number"
                onChange={inputChanged}
                value={inputValue}
                maxLength={7}
                autoFocus={true}
                placeholder={placeholderForRegPlate}
                ref={setRef}
                autoComplete="off"
              />
              <div className="spinner-container">{isLoading && <Spinner name="circle" />}</div>
            </div>
          </form>
          <div className="row">
            <div className="col-10 offset-1 col-sm-8 offset-sm-2 mb-2 text-center">
              <div style={errorStyle} className="invalid-feedback text-small text-center">
                {inputError}
              </div>
              {mailTo && (
                <div style={errorStyle} className="invalid-feedback text-small text-center">
                  {t('Contact us at')}
                  <a href={'mailto:' + mailTo} target="_top">
                    {' '}
                    {mailTo}{' '}
                  </a>
                  {t('for more information. Best regards, %dealerName', { dealerName: providerName || '' })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegistrationNumberEntry
