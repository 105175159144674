import React from 'react'
import classnames from 'classnames'
import { t } from 'translations/translationFunctions'

export interface IProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: number
  error?: string
}

const StartMileageInput = ({ onChange, value, error }: IProps) => {
  const classes: string = classnames('StartMileageInput')
  const inputClass = classnames('form-control', {
    'is-invalid': !!error,
  })
  const errorStyle = { display: 'block' }

  return (
    <section className={classes}>
      <div className="row">
        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
          <h3 className="mb-4 text-center">{t('Odometer state at contract start')}</h3>
          <div className={'d-lg-flex justify-content-center align-items-center component-margin-top-small mb-3'}>
            <input
              className={inputClass}
              name="startMileage-ax"
              onChange={onChange}
              value={value === undefined ? '' : value}
              min="0"
              type="number"
              autoComplete="off"
              placeholder={t('Current odometer state')}
            />
          </div>
          <div className="row">
            <div className="col-10 offset-1 col-sm-8 offset-sm-2 mb-2 text-center">
              <div style={errorStyle} className="invalid-feedback text-small text-center">
                {error}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StartMileageInput
