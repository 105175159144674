import ActionTypes from '../actions/ActionTypes'
import ActionTypeKeys from '../actions/ActionTypeKeys'

export const outageReducer = (state = null, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.SET_OUTAGE:
      return action.outage || null
    default:
      return state
  }
}
