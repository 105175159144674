import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { defaultBackUrl, providerPath, startPath } from '../routes/paths'
import './NavigationFooter.css'
import classnames from 'classnames'
import { connect, Dispatch } from 'react-redux'
import Spinner from 'react-spinkit'
import { t } from 'translations/translationFunctions'
import { IResetReduxStore, resetReduxStore } from '../../actions/fullStoreActions/fullStoreActions'
import IStoreState from '../../store/IStoreState'

export interface INavigationFooterProps {
  className?: string
  prev?: string
  next?: string
  nextTitle?: string
  displayRightArrowIcon?: boolean
  prevCallback?: (e: any) => void
  nextCallback?: (e: any) => void
  hideNext?: boolean
  hidePrev?: boolean
  isNextAvailable?: boolean | null
  isLoadingNext?: boolean
  shouldRedirect?: boolean
}

interface INavigationFooterReduxProps {
  resetReduxStore: () => IResetReduxStore
}

export const NavigationFooter: React.SFC<INavigationFooterProps & INavigationFooterReduxProps> = ({
  className,
  prev,
  next,
  nextTitle,
  displayRightArrowIcon = true,
  prevCallback,
  nextCallback,
  hideNext = false,
  shouldRedirect = true,
  hidePrev,
  isLoadingNext = false,
  isNextAvailable = null,
  resetReduxStore,
}: INavigationFooterProps & INavigationFooterReduxProps) => {
  const classes: string = classnames('NavigationFooter', 'component-margin-top', className)
  const nextClasses: string = classnames(
    'btn d-inline-flex align-items-center',
    { 'sam-btn-disabled': isNextAvailable === null ? !next : !isNextAvailable },
    { 'sam-btn-primary': isNextAvailable === null ? !!next : isNextAvailable },
  )

  const LinkWrapper = ({ to, children, ...props }: LinkProps) =>
    shouldRedirect ? (
      <Link to={to} {...props}>
        {children}
      </Link>
    ) : (
      <a tabIndex={1} {...props}>
        {children}
      </a>
    )

  return (
    <section className={classes}>
      <div className="row">
        <div className="col-sm-8 offset-sm-2">
          {!hidePrev && (
            <LinkWrapper
              to={prev ? providerPath(prev) : defaultBackUrl}
              onClick={prevCallback}
              className="btn sam-btn-primary mr-4"
              data-e2e={'Back-button'}
              title={t('Click to go back to the previous page')}>
              <i className="navigation-arrow fa fa-angle-left pr-2" />
              {t('Previous')}
            </LinkWrapper>
          )}
          {!hideNext && (
            <LinkWrapper
              to={next ? providerPath(next) : ''}
              onClick={nextCallback}
              className={nextClasses}
              data-e2e={'Next-button'}
              title={t('Click to continue')}>
              {nextTitle ? nextTitle : t('Next')}
              <div className="arrow-spinner pl-2">
                {isLoadingNext ? (
                  <div className="spinner">
                    <Spinner name="circle" color="white" fadeIn="none" />
                  </div>
                ) : (
                  <div>{displayRightArrowIcon && <i className="navigation-arrow fa fa-angle-right" />}</div>
                )}
              </div>
            </LinkWrapper>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          {!hidePrev && (
            <Link
              className="btn-reset"
              onClick={resetReduxStore}
              to={providerPath(startPath)}
              title={t('Click to go to the first page and start over')}>
              {t('Start over')}
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>): INavigationFooterReduxProps {
  return {
    resetReduxStore: () => dispatch(resetReduxStore()),
  }
}

export default connect(null, mapDispatchToProps)(NavigationFooter)
