import React from 'react'
import { History } from 'history'

/**
 * Default back button handling
 * @param history History object to use for going back in history
 */
export const defaultBack = (history: History) => {
  return (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    history.goBack()
  }
}
