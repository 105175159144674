import { formatMileage } from '@omnicar/sam-format'
import { IContractOptionResponse, IContractTemplateResponse, VehicleAlongItsContracts } from '@omnicar/sam-types'
import classnames from 'classnames'
import * as React from 'react'
import { t, tFuelType } from 'translations/translationFunctions'
import './SectionSummary.css'

export interface ISectionSummaryProps {
  className?: string
  vehicle?: VehicleAlongItsContracts
  duration?: number
  mileage?: number
  template?: IContractTemplateResponse
  options?: IContractOptionResponse[]
}

const SectionSummary: React.SFC<ISectionSummaryProps> = ({
  className,
  vehicle,
  duration,
  mileage,
  template,
  options,
}) => {
  /**
   * Render list of options
   * @param {ContractOptionResponse[]} options
   * @returns {any}
   */
  const renderOptionsList = (options?: IContractOptionResponse[]) => {
    let result
    if (options && options.length > 0) {
      result = options.map((option: IContractOptionResponse) => {
        return (
          <li key={option.id.toString()} title={option.description}>
            {option.description}
          </li>
        )
      })
    }
    return result
  }

  const optionsList = renderOptionsList(options)
  const classes: string = classnames('SectionSummary', 'component-margin-top-small', className)

  return (
    <section className={classes}>
      <div className="row">
        <div className="col-sm-8 offset-sm-2">
          <div className="box">
            {vehicle && (
              <h3 className="text-center SectionSummary__Heading">{`${vehicle.brand.name} ${
                vehicle.model.name
              }, ${tFuelType(vehicle.fuelType.name)}`}</h3>
            )}
            {template && <h4 className="text-center SectionSummary__Template">{`${template.description}`}</h4>}
            {duration && mileage && (
              <p className="text-center SectionSummary__DurationMileage">
                {`${t('Subscription Contract Duration')}:
                    ${duration / 12} ${t('year(s)')} /
                    ${formatMileage(mileage)} km`}
              </p>
            )}
            {options && options.length > 0 && (
              <div className="SectionSummary__Options">
                <p className="mb-0">{t('Additional options')}</p>
                <ul className="SectionSummary__Options__List">{optionsList}</ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionSummary
