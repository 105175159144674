import keys from '../ActionTypeKeys'
import { IBrandingPayloadResponse } from '@omnicar/sam-types'

export interface ISetProviderBranding {
  readonly type: keys.SET_PROVIDER_DATA
  readonly providerData: IBrandingPayloadResponse
}

export function setProviderData(providerData: IBrandingPayloadResponse): ISetProviderBranding {
  return {
    type: keys.SET_PROVIDER_DATA,
    providerData,
  }
}
