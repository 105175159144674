import * as React from 'react'
import classnames from 'classnames'
import Spinner from 'react-spinkit'

import './DurationMileageEntry.css'

interface IProps {
  className?: string
  title: string
  handleChange: (increase: boolean) => void
  unit: string
  info?: string
  selectedValue: string | number
  e2eId?: string
  isLoading: boolean
  disabled?: boolean
}

export default class DurationMileageEntry extends React.Component<IProps, {}> {
  public render() {
    const { className, title, unit, info, selectedValue, e2eId, isLoading, disabled } = this.props
    const classes: string = classnames('DurationMileageEntry', className, 'text-center')

    return (
      <section className={classes} data-e2e={e2eId}>
        <p className="title">{title}</p>
        <div className="row">
          <div className="col-10 offset-1 col-sm-6 offset-sm-3 d-flex">
            <div className="column">
              <button
                className="btn rounded-circle"
                onClick={this.handleChange}
                data-increment={false}
                disabled={disabled}
                data-e2e="Button-minus">
                <i className="fa fa-minus" aria-hidden="true" />
              </button>
            </div>
            <div className="column">
              <div className="selected-display">
                {isLoading ? (
                  <div className="spinner">
                    <Spinner name="circle" fadeIn="quarter" />
                  </div>
                ) : (
                  <p className="value">{selectedValue}</p>
                )}
                <div className="unit text-small">{unit}</div>
              </div>
            </div>
            <div className="column">
              <button
                className="btn rounded-circle"
                onClick={this.handleChange}
                data-increment={true}
                disabled={disabled}
                data-e2e="Button-plus">
                <i className="fa fa-plus" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        {info && <p className="text-dimmed mt-3">{info}</p>}
      </section>
    )
  }

  private handleChange = (e: React.MouseEvent<HTMLElement>) => {
    const dataAttrib: string = 'increment'
    const inc: string | undefined = e.currentTarget.dataset[dataAttrib]
    const isInc: boolean = inc === 'true'
    this.props.handleChange(isInc)
  }
}
