import * as React from 'react'
import classnames from 'classnames'
import './Summary.css'
import { t, tFuelType } from 'translations/translationFunctions'
import { ContractValueType, Customer, Other, ProductTypeContract } from '@omnicar/sam-types'
import { Vehicle } from '@omnicar/sam-types'
import { IContractOfferRequest } from '@omnicar/sam-types'
import { IBrandingPayloadResponse } from '@omnicar/sam-types'
import { formatMileage, formatDate } from '@omnicar/sam-format'
import { valueTypeTranslations as valueTranslations } from 'translations/ValueTypeTranslations'
import { TranslationKey } from 'translations/translationTypes'

interface IProps {
  className?: string
  customer?: Customer
  product?: Vehicle | Other
  contract?: IContractOfferRequest<ProductTypeContract>
  provider?: IBrandingPayloadResponse
  startMileage?: number | null
  startValue?: number | null
  valueType?: ContractValueType
}

const Summary: React.SFC<IProps> = ({
  className,
  customer,
  product,
  contract,
  startMileage,
  startValue,
  valueType,
  provider,
}) => {
  const Customer = customer && (
    <section className="customer-summary">
      <p>
        <strong className="Summary__title">{customer.name}</strong>
      </p>
      <p>{customer.address1}</p>
      <p>{`${customer.zip} ${customer.city}`}</p>
      <p>{`${t('Phone')}: ${customer.phone}`}</p>
      <p>{`${t('E-mail')}: ${customer.email}`}</p>
    </section>
  )

  const productFormatted = formatProductData(product)

  let startValueString = ''
  let value =
    (startMileage !== undefined && startMileage !== null && startMileage) ||
    (startValue !== undefined && startValue !== null && startValue)
  if (valueType === 'Mileage' && value) {
    startValueString = `${t('Odometer state at contract start')}: ${formatMileage(value)}`
  } else if (startValue && valueType) {
    startValueString = `${t(valueTranslations[valueType].valueAtStart)}: ${startValue}`
  }

  const Vehicle = product && 'vin' in product && 'vin' in productFormatted && (
    <div className="vehicle-summary">
      <p>
        <strong className="Summary__title">{productFormatted.productFormatted}</strong>
      </p>
      <p>{`${t('Fueltype')}: ${tFuelType(product.fuelType.name)}`}</p>
      <p>{`${t('Licenseplate')}: ${product.regNumber}`}</p>
      <p>{`${t('Vehicle identification number')}: ${productFormatted.vin}`}</p>
      <p>{`${t('Registered')}: ${productFormatted.regDate}`}</p>
      <p>{startValueString}</p>
    </div>
  )

  const Product = product && 'serialNumber' in product && 'itemNumber' in productFormatted && (
    <div className="product-summary">
      <p>
        <strong className="Summary__title">{productFormatted.productFormatted}</strong>
      </p>
      {product.fuelType && <p>{`${t('Fueltype')}: ${tFuelType(product.fuelType.name)}`}</p>}
      <p>{`${t('Serial Number')}: ${productFormatted.serialNumber}`}</p>
      <p>{`${t('Item Number')}: ${productFormatted.itemNumber}`}</p>
      <p>{`${t('Registered')}: ${productFormatted.regDate}`}</p>
      <p>{startValueString}</p>
    </div>
  )

  const contractFormatted = formatContractData(contract)

  const Contract = contract && (
    <div className="offer-summary">
      <p>
        <strong className="Summary__title">{contractFormatted.templateName}</strong>
      </p>
      <p>{contractFormatted.durationYears}</p>
      <p>{contractFormatted.value}</p>
      <p>{contractFormatted.valueTotal}</p>
    </div>
  )

  const IBrandingPayloadResponse = provider && (
    <div className="provider-summary">
      <p>
        <img className="f" src={provider.providerInformation.logo} alt="logo" />
      </p>
      <p>
        <strong className="Summary__title">{provider.providerInformation.administrativeName}</strong>
      </p>
      <p>{`${t('CVR/VAT#')}: ${provider.providerInformation.cvrCode}`}</p>
      <p>
        {`${t('Contact')}:${' '}`}
        <a className="summary-link" href={provider.providerInformation.contactFormUrl} target="blank">
          {provider.providerInformation.contactFormUrl}
        </a>
      </p>
    </div>
  )

  const cssClasses: string = classnames('Summary', className)

  return (
    <section className={cssClasses}>
      {customer && Customer}
      {product && 'vin' in product && Vehicle}
      {product && 'serialNumber' in product && Product}
      {contract && Contract}
      {provider && IBrandingPayloadResponse}
    </section>
  )
}

function formatProductData(product: Vehicle | Other | undefined) {
  return product && 'vin' in product ? formatVehicleData(product) : formatOtherProductData(product as Other | undefined)
}

function formatVehicleData(vehicle: Vehicle | undefined) {
  let productFormatted: string = ''
  let fuelType: string = ''
  let vin: string = t('Unknown')
  let regDate: string = t('Unknown')

  if (vehicle) {
    productFormatted = `${vehicle.brand.name} ${vehicle.model.name}`
    fuelType = vehicle.fuelType.name.toLowerCase()
    vin = vehicle.vin ? vehicle.vin : t('Unknown')
    regDate = vehicle.regDate && vehicle.regDate !== 'new' ? formatDate(vehicle.regDate) : t('Unknown')
  }

  return { productFormatted, fuelType, vin, regDate }
}

function formatOtherProductData(other: Other | undefined) {
  let productFormatted: string = ''
  let fuelType: string = ''
  let itemNumber: string = t('Unknown')
  let serialNumber: string = t('Unknown')
  let regDate: string = t('Unknown')

  if (other) {
    productFormatted = `${other.brand.name} ${other.model.name}`
    fuelType = other.fuelType ? other.fuelType.name.toLowerCase() : ''
    itemNumber = other.itemNumber ? getFullIdentifier(other.itemNumber, other.itemNumber2, '/') : t('Unknown')
    serialNumber = other.serialNumber ? getFullIdentifier(other.serialNumber, other.serialNumber2, '/') : t('Unknown')
    regDate = other.regDate && other.regDate !== 'new' ? formatDate(other.regDate) : t('Unknown')
  }

  return { productFormatted, fuelType, itemNumber, serialNumber, regDate }
}

function getFullIdentifier(nr1: string, nr2?: string, seperator?: string) {
  return nr1 ? `${nr1} ${nr2 ? `${seperator || ''} ${nr2}` : ''}` : ''
}

function formatContractData(contract: IContractOfferRequest<ProductTypeContract> | undefined) {
  let templateName: string = ''
  let durationYears: string = ''
  let value: string = ''
  let valueTotal: string = ''

  if (contract) {
    const { valueType } = contract

    templateName = contract.template ? contract.template.description : t('Service contract offer')
    durationYears = `${t('Duration in years')}: ${contract.duration / 12}`
    value = formatContractValue(
      contract.mileage,
      contract.value,
      valueType,
      valueTranslations[valueType || 'Mileage'].valueInTotal,
    )

    if (contract.template && contract.template.calculationMethod === 200) {
      const expreationMileage = contract.mileage + contract.startMileage || undefined
      const experationValue =
        contract.value !== undefined && contract.startValue !== undefined
          ? contract.value + contract.startValue
          : undefined

      valueTotal = formatContractValue(
        expreationMileage,
        experationValue,
        valueType,
        valueTranslations[valueType || 'Mileage'].expectedValueAtExperation,
      )
    }
  }

  return { templateName, durationYears, value, valueTotal }
}

function formatContractValue(
  mileage: number | null | undefined,
  value: number | null | undefined,
  valueType: ContractValueType | undefined,
  translation: TranslationKey,
) {
  let formattedValue: string = ''

  const val = mileage || value
  if (!val) {
    return ''
  }

  if ((!valueType && mileage) || (valueType === 'Mileage' && value)) {
    formattedValue = `${t(translation)}: ${formatMileage(val)}`
  } else if (valueType) {
    formattedValue = `${t(translation)}: ${val}`
  }

  return formattedValue
}

export default Summary as React.SFC<IProps>
