interface IConfig {
  support: {
    email: string
  }
}

const config: IConfig = {
  support: {
    email: 'support@fragus.com',
  },
}

export default config
