import {
  ContractState,
  IBrandingPayloadResponse,
  ICalculationResponse,
  IContractOfferRequest,
  IContractOfferResponse,
  IContractOptionResponse,
  IContractResponse,
  ProductAlongItsContracts,
  ProductTypeContract,
  VehicleAlongItsContracts,
} from '@omnicar/sam-types'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import Spinner from 'react-spinkit'
import { t } from 'translations/translationFunctions'
import { ISetOfferContract, setOfferContract } from '../../actions/contractActions/contractActions'
import { ISetPaymentInfo, setPaymentInfo } from '../../actions/paymentInfoActions/paymentInfoActions'
import {
  ISetPriceCalculation,
  setPriceCalculation,
} from '../../actions/priceCalculationActions/priceCalculationActions'
import { getContractById, getContractPDFByIdURL } from '../../api/api'
import { ISummaryOption, default as OptionsSummary } from '../../components/optionsSummary/OptionsSummary'
import PageHeader from '../../components/pageHeader/PageHeader'
import { getParameterByName, providerPath, receiptPath, summaryPath } from '../../components/routes/paths'
import Summary from '../../components/summary/Summary'
import IStoreState from '../../store/IStoreState'
import '../summary/SummaryPage.css'
import NavigationFooter from '../../components/navigationFooter/NavigationFooter'
import PaymentSection from '../../components/paymentSection/PaymentSection'
import { resolveProviderLocale } from '../../utils/locale'

interface IOverviewPageProps extends RouteComponentProps<any> {
  setPaymentInfo: (paymentInfo: IContractOfferResponse) => ISetPaymentInfo
  setPriceCalculation: (calculation: ICalculationResponse) => ISetPriceCalculation
  setOfferContract: (contractOffer: IContractOfferRequest<ProductTypeContract>) => ISetOfferContract
  paymentInfo: IContractOfferResponse
  contractOffer: IContractOfferRequest
  branding: IBrandingPayloadResponse
  providerData: IBrandingPayloadResponse | null
}

interface IOverviewPageState {
  confirmedTermsOfService: boolean
  confirmedTermsOfTrade: boolean
  errorTermsOfService: boolean
  errorTermsOfTrade: boolean
  contract: IContractResponse | null
  offerPdfUrl: string | null
  summaryOptions: ISummaryOption[]
  isLoading: boolean
  arOptionTermsComfirmed: boolean[]
}

class OverviewPage extends React.Component<IOverviewPageProps, IOverviewPageState> {
  constructor(props: IOverviewPageProps) {
    super(props)
    const arOptionTermsComfirmed: boolean[] = []
    props.contractOffer.options.forEach((option) => {
      arOptionTermsComfirmed.push(!option.termsOfService)
    })
    this.state = {
      confirmedTermsOfService: false,
      confirmedTermsOfTrade: false,
      errorTermsOfService: false,
      errorTermsOfTrade: false,
      contract: null,
      offerPdfUrl: null,
      summaryOptions: [],
      isLoading: false,
      arOptionTermsComfirmed,
    }

    this.getContract()
  }

  public render() {
    const { contractOffer, paymentInfo, providerData } = this.props
    const { contract, offerPdfUrl } = this.state

    if (!contract || !contractOffer) {
      return (
        <section className="OverviewPage OverviewPage--empty">
          <div className="spinner">
            <Spinner name="ball-spin-fade-loader" />
          </div>
        </section>
      )
    }

    const hidePayment = contract && contract.state !== ContractState.Offer
    const isVehicle = !!(contractOffer.product && 'vin' in contractOffer.product)
    const calculationMethod = contract.template ? contract.template.calculationMethod : contract.calculationMethod
    const termsOfServiceRef = contract.template ? contract.template.termsOfService.ref : contract.termsRef

    return (
      <section className="OverviewPage">
        <PageHeader pageTitle="summary" contract={contract} />
        <div className="page-content">
          <div className="row component-margin-top">
            <div className="col-sm-10 offset-sm-1">
              <div className="box">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="mb-3 summary-heading">
                      {isVehicle ? t('Customer and vehicle information') : t('Customer and product information')}
                    </h3>
                    <Summary className="mb-3" customer={contract.customer} />
                    <Summary
                      product={contract.product}
                      startMileage={calculationMethod === 200 ? contract.startMileage : null}
                      startValue={contract.startValue}
                      valueType={contract.valueType}
                    />
                  </div>
                  <div className="col-md-6 mt-3 mt-md-0">
                    <h3 className="summary-heading">{t('Subscription Contract')}</h3>
                    <Summary className="mt-sm" contract={contractOffer} />
                    <br />
                    <a href={offerPdfUrl || '#'} target="_blank" rel="noopener noreferrer">
                      <strong className="text-bolder">{t('Print offer')}</strong>
                    </a>
                    <br />
                    {termsOfServiceRef && (
                      <a href={termsOfServiceRef} target="_blank" rel="noopener noreferrer">
                        <strong className="text-bolder">{`${t('Terms & conditions')} (PDF)`}</strong>
                      </a>
                    )}
                    {contract.state === ContractState.Active && contract.termsOfTradeRef && (
                      <>
                        <br />
                        <a href={contract.termsOfTradeRef} target="_blank" rel="noopener noreferrer">
                          <strong className="text-bolder">{`${t('Terms of Trade')} (PDF)`}</strong>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {this.state.summaryOptions.length > 0 && (
                <div className="box mt-md-3">
                  <OptionsSummary options={this.state.summaryOptions} />
                </div>
              )}
              {!hidePayment && paymentInfo && (
                <PaymentSection
                  className="summary-payment-section"
                  onNext={this.nextCallback}
                  contractId={paymentInfo.contractId}
                  token={paymentInfo.token}
                  locale={resolveProviderLocale(providerData && providerData.locale)}
                />
              )}
              {hidePayment && (!paymentInfo || !paymentInfo.token) && (
                <NavigationFooter
                  next={providerPath(summaryPath)}
                  hidePrev={true}
                  nextCallback={this.nextCallback}
                  nextTitle={t('Go to payment')}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    )
  }

  private mapOptions = (options: IContractOptionResponse[]): ISummaryOption[] =>
    options.map((option) => ({ option, readOnly: true, selected: true }))

  private getContract = async () => {
    const accessToken = getParameterByName('access-token', window.location.href)
    const contractId: number = parseInt(this.props.match.params.contractId, 10)
    const response = await getContractById(contractId, accessToken)

    if (!response.data) {
      return this.props.history.push('/not-found')
    }

    const contract = response.data

    const product =
      'vin' in contract.product
        ? (contract.product as VehicleAlongItsContracts)
        : (contract.product as ProductAlongItsContracts)
    const contractOffer: IContractOfferRequest<VehicleAlongItsContracts | ProductAlongItsContracts> = {
      contractProviderId: contract.contractProvider.contractProviderId,
      userId: contract.customer.id,
      customer: contract.customer,
      product: product,
      duration: parseInt(contract.duration, 10),
      mileage: parseInt(contract.mileage, 10),
      startMileage: contract.startMileage,
      startValue: contract.startValue,
      value: contract.value !== undefined ? parseInt(contract.value, 10) : undefined,
      valueType: contract.valueType,
      options: contract.options,
      template: contract.template || undefined,
      paymentGateway: contract.paymentGateway,
      startDateISOString: '',
    }
    const summaryOptions = this.mapOptions(contract.options)
    this.setState({ contract, summaryOptions })

    const paymentInfo: IContractOfferResponse = {
      contractId,
      token: accessToken,
      paymentGateway: contract.paymentGateway,
      prettyIdentifier: contract.prettyIdentifier,
    }
    this.props.setPaymentInfo(paymentInfo)
    this.props.setPriceCalculation(contract.payment)
    this.props.setOfferContract(contractOffer)
    this.setState({ offerPdfUrl: getContractPDFByIdURL(paymentInfo.contractId, paymentInfo.token) })
  }

  private nextCallback = () => this.props.history.push(providerPath(receiptPath))
}

function mapStateToProps(state: IStoreState) {
  return {
    paymentInfo: state.paymentInfo,
    contractOffer: state.contractOffer,
    branding: state.providerData,
    providerData: state.providerData,
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>) {
  return {
    setPaymentInfo: (paymentInfo: IContractOfferResponse) => dispatch(setPaymentInfo(paymentInfo)),
    setPriceCalculation: (calculation: ICalculationResponse) => dispatch(setPriceCalculation(calculation)),
    setOfferContract: (contractOffer: IContractOfferRequest) => dispatch(setOfferContract(contractOffer)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage as any)
