import { IContractOptionResponse } from '@omnicar/sam-types'
import * as React from 'react'
import { t } from 'translations/translationFunctions'
import OptionsSummaryItem, { IOptionsSummaryItemProps } from './OptionsSummaryItem'

interface IOptionsSummaryProps {
  options: ISummaryOption[]
  handleChange?: (e: React.ChangeEvent<HTMLElement>) => void
}

export interface ISummaryOption {
  option: IContractOptionResponse
  selected?: boolean
  readOnly: boolean
}

const OptionsSummary: React.SFC<IOptionsSummaryProps> = ({ options, handleChange }) => {
  // if there are selected options -> show options summary
  const isSelectedOptions: ISummaryOption | undefined = options.find((option: ISummaryOption) => !!option.selected)

  return (
    <section className="OptionsSummary">
      <h3 className={`mb-3 summary-heading ${!isSelectedOptions && 'd-print-none'}`}>{t('Additional options')}</h3>
      <ul>
        {options.map((option: ISummaryOption) => {
          const props: IOptionsSummaryItemProps = { option }

          // only enable change handler if option is not 'read only'
          if (!option.readOnly && handleChange) {
            props.handleChange = handleChange
          }
          return <OptionsSummaryItem key={option.option.id} {...props} />
        })}
      </ul>
    </section>
  )
}

export default OptionsSummary
