import React from 'react'
import './Popup.css'
import { resetPasswordPath } from 'components/routes/paths'
import Spinner from 'react-spinkit'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'

interface IProps {
  header: TranslationKey
  text?: TranslationKey
  hreftext?: TranslationKey
  hreftooltip?: TranslationKey
  children?: React.ReactNode
}

const Popup: React.SFC<IProps> = ({ children, header, text, hreftext, hreftooltip }) => {
  const childrenElement = children || <Spinner name="circle" color="#128bb0" fadeIn="none" />
  const url = resetPasswordPath
  return (
    <div className="Popup__box">
      <div className="Popup__inner">
        <h4>{t(header)}</h4>
        <h4>{text && t(text)}</h4>
        {hreftext && (
          <h4>
            <a href={url} title={hreftooltip && t(hreftooltip)} target="_blank" rel="noopener noreferrer">
              {t(hreftext)}
            </a>
          </h4>
        )}
        {childrenElement}
      </div>
    </div>
  )
}

export default Popup
