import React from 'react'
import { IOutageResponse as OutageType } from '@omnicar/sam-types/types/outage'
import { IOutageResponse as APIOutage } from '@omnicar/sam-types'
import { connect } from 'react-redux'
import IStoreState from 'store/IStoreState'
import { toast, TypeOptions } from 'react-toastify'
import { dismiss, showOutage, ToastId, updateToast } from 'utils/toastify'

interface IProps {
  onOutageChange?: (isActiveOutage: boolean) => void
}

interface IActionProps {
  outage: APIOutage | undefined
}

type TProps = IProps & IActionProps

let oldOutage: OutageType | undefined

class Outage extends React.Component<TProps> {
  public render() {
    const { onOutageChange } = this.props
    const outage = this.props.outage || undefined
    const toastType: TypeOptions = (outage && this.getToastType(outage.severityLevel || 1)) || toast.TYPE.DEFAULT
    onOutageChange && onOutageChange(!!outage)

    oldOutage &&
      outage &&
      oldOutage.severityLevel !== outage.severityLevel &&
      updateToast(outage.headerText!, toastType, ToastId.Outage)

    oldOutage = outage
    // no outage remove
    if (outage === undefined) {
      dismiss(ToastId.Outage)
    }

    return !!outage && outage.headerText && showOutage(outage.headerText, toastType) && false
  }

  private getToastType = (severityLevel: number): TypeOptions => {
    let toastType: TypeOptions = toast.TYPE.DEFAULT
    switch (severityLevel) {
      case 1:
        toastType = toast.TYPE.INFO
        break
      case 2:
        toastType = toast.TYPE.WARNING
        break
      case 3:
        toastType = toast.TYPE.ERROR
        break
    }
    return toastType
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    outage: state.outage,
  }
}

export default connect(mapStateToProps)(Outage)
