import * as React from 'react'
import { t } from 'translations/translationFunctions'
import './PageHeader.css'
import { ContractState, IBrandingPayloadResponse, IContractResponse } from '@omnicar/sam-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import IStoreState from '../../store/IStoreState'
import { formatDate } from '../../utils/formatDate'

interface IPageHeaderProps extends RouteComponentProps<any> {
  pageTitle?: string
  contract?: IContractResponse
  providerData?: IBrandingPayloadResponse
}
class PageHeader extends React.Component<IPageHeaderProps, any> {
  public render() {
    const { contract } = this.props
    const message = this.getMessage(contract)

    return (
      <section className="PageHeader">
        {message && <h3 className="PageHeader__message">{message}</h3>}
        {/* <img className="PageHeader__branding-logo" src={logoPath} /> */}
      </section>
    )
  }

  private getMessage = (contract: IContractResponse | undefined) => {
    let message = ''

    if (contract) {
      switch (contract.state) {
        case ContractState.Active:
          message = t('The contract has already been activated.')
          break
        case ContractState.Draft:
          message = t('This offer is a draft')
          break
        case ContractState.Offer:
          message = t('This offer expires on %date', {
            date: formatDate(moment(contract.offerExpiresAt).toDate()),
          })
          break
        case ContractState.OfferExpired:
          message = t('The offer has expired. Contact your provider.')
          break
        case ContractState.OfferRejected:
          message = t('The offer has been rejected. Contact your provider.')
          break
        case ContractState.OfferRevoked:
          message = t('The offer has been revoked. Contact your provider.')
          break
        case ContractState.Settled:
          message = t('The contract has been settled.')
          break
        case ContractState.Suspended:
          message = t('The contract is suspended. Contact your provider.')
          break
        case ContractState.Terminated:
          message = t('The contract has been terminated. Contact your provider.')
          break

        default:
          break
      }
    }

    return message
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    providerData: state.providerData,
  }
}

export default connect(mapStateToProps, null)(PageHeader as any) as any
