import IStoreState from './IStoreState'

export function getStoredState(): IStoreState | undefined {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState !== null) {
      const state = JSON.parse(serializedState) as IStoreState
      return state
    } else {
      return undefined
    }
  } catch (error) {
    return undefined
  }
}

export function saveState(state: IStoreState | {}): boolean {
  if (state) {
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem('state', serializedState)
      return true
    } catch (error) {
      return false
    }
  }
  return false
}

export function clearLocalStorage() {
  localStorage.clear()
}

export const getGenericToken = (): string | null => localStorage.getItem('generic-token')

export const saveGenericToken = (token: string) => localStorage.setItem('generic-token', token)
