import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { connect, Dispatch } from 'react-redux'
import { startPath, errorPath, providerPath, getProviderId } from '../components/routes/paths'
import { resetReduxStore, IResetReduxStore } from '../actions/fullStoreActions/fullStoreActions'
import IStoreState from '../store/IStoreState'
import { clearLocalStorage } from '../store/localstorage'
import { showWarning } from 'utils/toastify'
import { t } from 'translations/translationFunctions'

/*
  Every Page is wrapped inside this HOC.
  The isStateAllowed is a flag whether the wrapped Component
  is in the right state, e.g. has all the required props.
*/
interface IRequiredPropsWrapperState {
  isStateAllowed: boolean
  isWebCalcAllowed: boolean | null
  isPaymentPage: boolean | null
}
interface IRequiredPropsWrapperProps {
  resetReduxStore: () => IResetReduxStore
}
interface IRequiredPropsStateProps {
  providerId: number | null
  providerWebCalcAllowed: boolean | null
  paymentPresent: number | null
}

export const withRequiredProps = (isStateComplete: (s: any) => boolean) => {
  return (WrappedComponent: React.ComponentClass<any>) => {
    class HigherOrderComponent extends React.Component<
      IRequiredPropsWrapperProps & IRequiredPropsStateProps,
      IRequiredPropsWrapperState
    > {
      public constructor(props: any) {
        super(props)

        const isStateAllowed = isStateComplete(this.props)
        const isProviderIdValid = this.validateProviderId(this.props.providerId)

        this.state = {
          isStateAllowed: isStateAllowed && isProviderIdValid,
          isWebCalcAllowed: this.props.providerWebCalcAllowed && isProviderIdValid,
          isPaymentPage: !!this.props.paymentPresent,
        }
        if (!this.state.isStateAllowed) {
          // reset storage / store with providerData present
          this.props.resetReduxStore()
          clearLocalStorage()
          // display warning
          console.log('Invalid state')
          showWarning(t('Something went wrong. Please try to refresh the application.'))
        }
      }

      public render() {
        return this.state.isWebCalcAllowed ? (
          this.state.isStateAllowed ? (
            <WrappedComponent {...this.props} />
          ) : (
            <Redirect to={providerPath(startPath)} />
          )
        ) : this.state.isPaymentPage && this.state.isStateAllowed ? (
          <WrappedComponent {...this.props} />
        ) : (
          <Redirect to={providerPath(errorPath)} />
        )
      }

      private validateProviderId = (id: number | null): boolean => {
        // get provider id from current url
        return !!(id && id === parseInt(getProviderId(), 10))
      }
    }

    function mapStateToProps(state: IStoreState): IRequiredPropsStateProps {
      return {
        providerId: state.providerData && state.providerData.providerInformation.contractProviderId,
        providerWebCalcAllowed: state.providerData && state.providerData.providerInformation.webCalcEnabled,
        paymentPresent: state.paymentInfo && state.paymentInfo.contractId,
      }
    }

    function mapDispatchToProps(dispatch: Dispatch<IStoreState>): IRequiredPropsWrapperProps {
      return {
        resetReduxStore: () => dispatch(resetReduxStore()),
      }
    }

    return connect(mapStateToProps, mapDispatchToProps)(HigherOrderComponent)
  }
}
