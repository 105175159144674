import * as React from 'react'
import classnames from 'classnames'

import './SamCheckbox.css'

interface ISamCheckboxProps {
  label?: string
  className?: string
  checked: boolean
  value?: number | string
  onChange?: (e: React.ChangeEvent<HTMLElement>) => void
  id?: number | string
  readOnly?: boolean
}
const SamCheckbox: React.SFC<ISamCheckboxProps> = ({
  label = '',
  className,
  checked,
  value,
  onChange,
  id,
  readOnly = false,
}) => {
  const classes: string = classnames(
    'SamCheckbox',
    'custom-control',
    'custom-checkbox',
    label !== '' ? 'has-label' : '',
    'd-print-checkbox-none',
    className,
  )
  return (
    <div className={classes}>
      <input
        className="custom-control-input"
        id={'sam-check-' + id}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        data-option-id={id}
        readOnly={readOnly}
        disabled={readOnly}
      />
      <label
        className="custom-control-label"
        htmlFor={'sam-check-' + id}
        data-e2e={'option-entry-' + (checked ? 'checked' : 'unchecked')}>
        {label}
      </label>
    </div>
  )
}

export default SamCheckbox
